<template>
  <div style="overflow:hidden">
    <div
      style="height: 70vh !important"
      class="text-center d-flex flex-1 justify-content-center align-items-center"
      v-if="IsLoading"
    >
      <b-spinner variant="success" type="grow"></b-spinner>
    </div>
    <div
      v-if="!IsLoading"
      class="d-flex align-items-center justify-content-center"
    >
      <b-col v-if="false" cols="4">
        <b-card>
          <div class="d-flex align-items-center justify-content-center">
            <h1 class="logo text-primary">
              {{ data.first_name[0] + "" + data.last_name[0] }}
            </h1>
          </div>
          <div class="mt-2 d-flex align-items-center justify-content-center">
            <h2>{{ data.first_name + " " + data.last_name }}</h2>
          </div>
          <hr />
          <div class="mt-1 d-flex align-items-center justify-content-center">
            <div class="role text-white">{{ data.role }}</div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="11" v-if="!IsLoading">
        <div class="mb-2">
          <b-row cols="12">
            <b-col
              cols="4"
              class="d-flex align-items-center justify-content-center"
            >
              <b-button
                @click="toggleDiv = 0"
                :variant="toggleDiv === 0 ? 'primary' : 'none'"
                class="text-secondary w-100"
              >
                <feather-icon icon="UserCheckIcon" />
                Info
              </b-button>
            </b-col>

            <b-col
              cols="4"
              class="d-flex align-items-center justify-content-center"
            >
              <b-button
                @click="toggleDiv = 1"
                :variant="toggleDiv === 1 ? 'primary' : 'none'"
                class="text-secondary w-100"
              >
                <feather-icon icon="LockIcon" />
                Security
              </b-button>
            </b-col>

            <b-col
              cols="4"
              class="d-flex align-items-center justify-content-center"
            >
              <b-button
                @click="toggleDiv = 2"
                :variant="toggleDiv === 2 ? 'primary' : 'none'"
                class="text-secondary w-100"
              >
                <feather-icon icon="DollarSignIcon" />
                Billing & Plan
              </b-button>
            </b-col>
          </b-row>
        </div>
        <b-card title="Account Details" v-if="toggleDiv === 0">
          <!-- <template #header>
            <h3 class="">Account Details</h3>
          </template> -->
          <b-row class="justify-content-between">
            <b-col sm="12" md="6">
              <label for="First Name">First Name :</label>
              <validation-provider
                #default="{ errors }"
                name="First Name"
                rules="required"
              >
                <b-form-input
                  v-model="data.first_name"
                  placeholder="First Name"
                  name="first_name"
                  label="First Name"
                  @click="errorsU.first_name = null"
                  :state="
                    errors.length > 0 || errorsU.first_name ? false : null
                  "
                />
                <small class="text-danger"
                  >{{ errors[0] }} {{ errorsU.first_name }}</small
                >
              </validation-provider>
            </b-col>
            <b-col sm="12" md="6" class="mt-2 mt-md-0 mt-lg-0 mt-xl-0">
              <label for="Last Name">Last Name :</label>
              <validation-provider
                #default="{ errors }"
                name="Last Name"
                rules="required"
              >
                <b-form-input
                  v-model="data.last_name"
                  rules="required"
                  name="last_name"
                  placeholder="Last Name"
                  label="Last Name"
                  @click="errorsU.last_name = null"
                  :state="errors.length > 0 || errorsU.last_name ? false : null"
                />
                <small class="text-danger"
                  >{{ errors[0] }} {{ errorsU.last_name }}
                </small>
              </validation-provider>
            </b-col>
            <b-col sm="12" md="6" class="mt-2">
              <label for="email">Email :</label>
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required"
              >
                <b-form-input
                  disabled
                  rules="'required|email'"
                  type="email"
                  name="email"
                  placeholder="johndoe@example.com"
                  v-model="data.email"
                  label="Email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col sm="12" md="6" class="mt-2">
              <label for="email">Phone :</label>
              <b-form-input
                type="number"
                placeholder="0612121212"
                name="phone"
                v-model="data.phone"
                :state="errorsU.phone ? false : null"
                @click="errorsU.phone = null"
                label="Phone"
              />
              <small class="text-danger">{{ errorsU.phone }} </small>
              <!-- <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span> -->
            </b-col>
            <b-col sm="12" md="6" class="mt-2">
              <label for="address">Address :</label>
              <b-form-textarea
              :state="errorsU.address ? false : null"
              
                @click="errorsU.address = null"
                placeholder="your address"
                v-model="data.address"
                name="address"
              />
              
              <small class="text-danger">{{ errorsU.address }} </small>
            </b-col>
            <b-col sm="12" md="6" class="mt-2">
              <label for="Country">Country :</label>
              <b-form-select v-model="data.country" label="Country">
                <option
                  :key="index"
                  :value="item.value"
                  v-for="(item, index) in countryOptions"
                >
                  {{ item.text }}
                </option>
              </b-form-select>
            </b-col>
            <b-col cols="12" class="mt-2">
              <div class="con-notifications">
                <b-button
                  variant="primary"
                  block
                  @click="updateInfo"
                  :disabled="!validateFormAccount"
                  >Save</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Change Password" v-if="toggleDiv === 1">
          <b-row class="justify-content-between">
            <b-col sm="12" md="6" >
              <label for="Old Password">Old Password :</label>
              <validation-provider
                #default="{ errors }"
                name="Old Password"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    type="password"
                    v-model="edit_password.oldPassword"
                    name="oldPassword"
                    placeholder="Old password"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-col>
            <b-col sm="12" md="6" class="mt-2 mt-md-0 mt-lg-0 mt-xl-0">
              <label for="New Password">New Password :</label>
              <validation-provider
                #default="{ errors }"
                name="New Password"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    type="password"
                    v-model="edit_password.password"
                    name="password"
                    placeholder="New password"
                    :state="errors.length > 0 ? false : null"
                    :type="passwordFieldType"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-col>
            <b-col cols="12" class="mt-2">
              <label for="Confirm Password">Confirm Password :</label>
              <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    type="password"
                    v-model="edit_password.password_confirmation"
                    name="password_confirmation"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Confirm password"
                    :type="passwordFieldType"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-col>
            <b-col cols="12" class="mt-3">
              <b-button
                variant="primary"
                block
                type="filled"
                @click="editPassword"
                :disabled="
                  !edit_password.password ||
                  !edit_password.oldPassword ||
                  !edit_password.password_confirmation
                "
                >Change Password</b-button
              >
            </b-col>
          </b-row>
        </b-card>

        <b-card title="Current Plan" v-if="toggleDiv === 2">
          <PlanManagementVue />
        </b-card>
      </b-col>
    </div>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex } from "@validations";
import axios from "@axios";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import {
  BButton,
  BForm,
  BFormInput,
  BFormGroup,
  BCard,
  BImg,
  BLink,
  BCardTitle,
  BCardText,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BSpinner,
  BCol,
  BRow,
  BFormTextarea,
  BFormSelect,
  BContainer,
} from "bootstrap-vue";
import PlanManagementVue from "../../components/profile/PlanManagement.vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BImg,
    BCardTitle,
    BLink,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    BSpinner,
    BCol,
    BRow,
    BFormTextarea,
    BFormSelect,
    BContainer,
    regex,
    required,
    PlanManagementVue,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      IsLoading: false,
      toggleDiv: 0,
      data: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        address: "",
        country: "",
      },
      status: "",
      edit_password: {
        oldPassword: null,
        password: null,
        password_confirmation: null,
      },
      errorsU: {},
      errorsPassword: [],
      errorAccount: [],
      countryOptions: [
        { text: "Maroc", value: "maroc" },
        { text: "France", value: "france" },
        { text: "Canada", value: "canada" },
      ],
    };
  },
  computed: {
    validateFormPassword() {
      return (
        this.edit_password.oldPassword !== "" &&
        this.edit_password.password !== "" &&
        this.edit_password.password_confirmation !== ""
      );
    },
    validateFormAccount() {
      return (
        this.data.first_name !== "" &&
        this.data.last_name !== "" &&
        this.data.email !== ""
      );
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    editPassword() {
      if (!this.validateFormPassword) return;
      this.IsLoading = true;
      this.$store
        .dispatch("profile/updatePassword", this.edit_password)
        .then((response) => {
          if (response.data.status === "success" || response.data.status === "Success") {
            this.edit_password = {};
            this.errorsPassword = [];
            this.showToast(
              "success",
              "CheckIcon",
              "Success !",
              response.data.message
            );
          } else {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error!",
              response.data.message
            );
          }

          this.IsLoading = false;
        })
        .catch((error) => {
          this.IsLoading = false;
          if(error.data.message) {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error!",
            error.data.message
          );

          }
              if(error.data.errors.password) {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error!",
            error.data.errors.password[0]
          );
          }
          this.status = error.response ? error.response.status : "";
          this.errorsPassword = error.response ? error.response.data : [];
        });
    },
    updateInfo() {
      this.IsLoading = true;
      if (!this.validateFormAccount) return;
      this.$store
        .dispatch(`profile/updateInfo`, this.data)
        .then((response) => {
          location.reload();
          this.data = response.data.user;
          this.errorAccount = [];
          this.showToast(
            "success",
            "CheckIcon",
            "success!",
            "The account has been updated successfully."
          );
          this.IsLoading = false;
        })
        .catch((error) => {
          this.IsLoading = false;
          const { errors } = error.data;
          if (errors.last_name) {
            this.errorsU.last_name = errors.last_name[0];
          }
          if (errors.first_name) {
            this.errorsU.first_name = errors.first_name[0];
          }
          if (errors.phone) {
            this.errorsU.phone = errors.phone[0];
          }
            if (errors.address) {
            this.errorsU.address = errors.address[0];
          }
        });
    },
    // async fetchPlan() {
    //   try {
    //     await axios
    //       .get(`paypal-plan-by-user-id/${this.data.id}`)
    //       .then(({ data }) => {})
    //       .catch((err) => {});
    //   } catch (error) {}
    // },
    async getUserData() {
      this.IsLoading = true;
      await this.$store
        .dispatch("profile/fetchUser")
        .then(async (response) => {
          this.IsLoading = false;
          this.data = await response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
watch: {
  '$route.params.id': function (newId, oldId) {
    this.toggleDiv = parseInt(newId);
  }
},

  mounted() {
    this.toggleDiv = parseInt(this.$route.params.id);
    this.getUserData();
  },
};
</script>

<style lang="scss" scoped>
.logo {
  font-size: 5rem;
  padding: 1rem;
  background-color: #1095f41f;
  border-radius: 5px;
}

.role {
  background-color: #1f262cb5;
  padding: 3px 17px;
  border-radius: 3px;
}

@import "@core/scss/vue/pages/page-auth.scss";
</style>
