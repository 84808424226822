<template>
  <div>
    <!-- <div v-if="(userSubPlan) || !planCheck"> -->
    <div v-if="items || !planCheck">
      <div>
        <b-row>
          <b-col sm="12" md="6">
            <h6>
              Your Plan is <span v-if="!planCheck">Basic</span>
              <span class="plan-type text-success" v-else>Pro</span>
            </h6>
            <p class="text-sm mb-1">A simple start for everyone</p>
            <h6>
              Active until <span v-if="!planCheck">you Upgrade</span>
              <span v-else>
                {{
                  moment(currentInvoice.date_expiration).format("MMM DD, YYYY")
                }}</span
              >
            </h6>
            <!-- <p v-if="planCheck">
            We will send you a notification upon Subscription expiration
          </p> -->
            <!-- <p v-if="!planCheck">Basic plan for small to medium businesses</p>
          <p v-else>Premium Plan</p> -->
          </b-col>
          <b-col sm="12" md="6">
            <b-alert show class="p-1" variant="warning" v-if="!planCheck"
              >We need your attention!
              <p><small> Your plan need to update </small></p>
            </b-alert>
            <b-alert show class="p-1" variant="warning" v-else>
              Your next Payment on
              {{
                moment(currentInvoice.date_expiration).format("MMM DD, YYYY")
              }}
            </b-alert>

            <p v-if="planCheck">
              We will send you a notification upon Subscription expiration
            </p>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-button
            variant="primary"
            to="/pages/plan-selection"
            class="mx-1"
            v-if="!planCheck"
          >
            Upgrade Plan
          </b-button>
          <!-- <b-button variant="outline-danger" class="ml-1" v-else @click="cancelSubscription">
            Cancel Subscription
          </b-button> -->
        </b-row>
      </div>

      <b-table v-if="items" striped hover responsive :items="items" :fields="fields">
        <template #cell(date_payment)="data">
          {{
            data.value ? moment(data.value).format("MMM DD, YYYY") : data.value
          }}
        </template>
        <template #cell(amount_total)="data">
          <span>{{ data.value }} $</span>
        </template>
        <template #cell(status_payment)="data">
          <span v-if="data.value">
            <b-badge variant="success">paid</b-badge>
          </span>
          <span v-else>
            <b-button :to="`/pages/payment-method?same_id=${data.item.id}&plan_id=1`" style="padding:6px 16px" variant="outline-warning">pay now</b-button>
            </span>
        </template>
      </b-table>
      <b-skeleton-table
        v-if="planCheck && !items"
        :rows="5"
        :columns="4"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
    <div v-else>
      <b-container>
        <b-row>
          <b-col>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
          </b-col>
          <b-col>
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
          </b-col>
        </b-row>
        <b-skeleton-table
          :rows="3"
          :columns="5"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
      </b-container>
    </div>
  </div>
</template>

<script>
import {
  BTable,
  BButton,
  BCard,
  BAlert,
  BModal,
  BFormInput,
  BSkeletonTable,
  BContainer,
  BRow,
  BCol,
  BSkeleton,
  BBadge,
} from "bootstrap-vue";
import axios from "@axios";
import Loader from "../Loader/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
export default {
  components: {
    BTable,
    BButton,
    BCard,
    BAlert,
    Loader,
    BModal,
    BFormInput,
    BSkeletonTable,
    BContainer,
    BRow,
    BCol,
    BSkeleton,
    BBadge
  },
  data() {
    return {
      moment,

      fields: [
        {
          key: "reference",
          label: "Reference",
          class: "text-center",
        },
        {
          key: "type_payment",
          label: "Type Payement",
          class: "text-center",
        },
        {
          key: "date_payment",
          label: "Payement date",
          class: "text-center",
          sortable: true,
        },
        {
          key: "amount_total",
          label: "Price",
          class: "text-center",
          sortable: true,
        },
        {
          key: "status_payment",
          label: "Status",
          class: "text-center",
        },
      ],
      items: null,
      subscreption_id: null,
      reason: null,
      lastPayement: null,
      cancellationReason: "",
      transactionsInfo: "",
      currentInvoice: null,
    };
  },
  watch: {},
  async mounted() {
    this.getTransactions();
    if (this.planCheck) {
      this.getCurrentTransaction();
    }
  },
  computed: {
    planCheck() {
      return this.$store.getters.getplanCheck;
    },

    futurePaymentDate() {
      if (this.lastPayment) {
        const lastPaymentDate = new Date(this.lastPayment);
        lastPaymentDate.setDate(lastPaymentDate.getDate() + 30);

        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        const month = months[lastPaymentDate.getMonth()];
        const day = lastPaymentDate.getDate();
        const year = lastPaymentDate.getFullYear();

        return `${month} ${day}, ${year}`;
      } else {
        return null;
      }
    },
  },
  methods: {
    showCancellationModal() {
      this.$bvModal.show("cancellation-modal");
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },

    async getTransactions() {
      try {
        await axios.get(`/invoices-list`).then(({ data }) => {
          this.items = data.data;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getCurrentTransaction() {
      try {
        await axios.get("/current-ongoing-invoice").then(({ data }) => {
          this.currentInvoice = data.data;
        });
      } catch (error) {}
    },
  },
};
</script>

<style scoped>
.plan-type {
  padding: 2px 4px;
  background-color: #10f41c1f;
  border-radius: 2px;
}
</style>
